import React from 'react';
import MobileWrapper from './components/mobileWrapper/MobileWrapper';
import Game from './components/game/Game';

function App() {
  return (
    <MobileWrapper>
      <Game />
    </MobileWrapper>
  );
}

export default App;
