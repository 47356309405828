import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "./Game.css";
import ProgressBar from "@ramonak/react-progress-bar";

// Backend URL (adjust this to your actual backend URL)
// const baseUrl = "http://localhost:4000";
const baseUrl = "https://api.tap.testinghub.site";

const Game = () => {
  const [count, setCount] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [telegramID, setTelegramID] = useState("");
  const [progress, setProgress] = useState(0);
  const [challengeCompleted, setChallengeCompleted] = useState(false);
  const [challengeFailed, setChallengeFailed] = useState(false); // Track if the challenge is failed
  const [remainingTime, setRemainingTime] = useState(null);
  const [maxTaps, setMaxTaps] = useState(1000);

  // Extract user and challenge data from backend
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tid = urlParams.get("tid");
    setTelegramID(tid);

    // Fetch user and challenge data from the backend
    const fetchUserData = async () => {
      try {
        const userResponse = await axios.get(`${baseUrl}/api/user/${tid}`);
        setFirstName(userResponse.data.firstName);

        const challengeResponse = await axios.get(`${baseUrl}/api/challenge/${tid}`);
        const { tapCount, challengeStatus, challengeEndTime } = challengeResponse.data;

        setCount(tapCount);
        setProgress((tapCount / maxTaps) * 100);

        const currentTime = new Date().valueOf();
        const remainingTimeInMillis = new Date(challengeEndTime).valueOf() - currentTime;

        // Check if the challenge has been completed or failed
        if (challengeStatus === "SUCCESS" || tapCount >= maxTaps) {
          setChallengeCompleted(true); // Mark the challenge as completed
        } else if (remainingTimeInMillis <= 0 && challengeStatus !== "SUCCESS") {
          setChallengeFailed(true); // Mark the challenge as failed if time has passed
        } else {
          setRemainingTime(remainingTimeInMillis); // Update remaining time
        }
      } catch (error) {
        console.error("Error fetching user or challenge data:", error);
      }
    };

    fetchUserData();
  }, [maxTaps]);

  // Update the tap count and challenge progress
  const updateScore = useCallback(
    debounce(async (newCount) => {
      try {
        const response = await axios.put(`${baseUrl}/api/coin/update`, {
          telegramID,
          tapCount: newCount,
        });

        const { message } = response.data;
        if (message.includes("Challenge completed") || newCount >= maxTaps) {
          setChallengeCompleted(true);
        }
      } catch (error) {
        console.error("Error updating user score:", error);
      }
    }, 1000),
    [telegramID]
  );

  // Handle user taps
  const handleTap = () => {
    if (challengeCompleted || challengeFailed) return; // Disable tapping if the challenge is completed or failed

    const newCount = count + 1;
    setCount(newCount);
    setProgress(Math.min((newCount / maxTaps) * 100, 100).toFixed(2));

    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
    }, 50);

    updateScore(newCount);
  };

  return (
    <div className="game-container">
      <div className="user-info">
        <h2>Hello, {firstName}!</h2>
      </div>

      <div className="score-board">
        <img src="/images/tap-icon.png" alt="Tap" className="reward-icon" />
        <div className="score">{count}</div>
      </div>

      <div className="progress-container">
        <ProgressBar
          completed={progress}
          label={`${count} / ${maxTaps} Taps`}
          customLabelStyles={{ color: "#fff", fontSize: "14px" }}
          bgColor="#cf9e18"
          baseBgColor="#e0e0e0"
          height="30px"
          borderRadius="5px"
          width="100%"
          isLabelVisible={true}
        />
      </div>

      {/* Show the appropriate message based on challenge status */}
      {challengeCompleted ? (
        <div className="challenge-message">
          <h3>Today's challenge completed successfully!</h3>
        </div>
      ) : challengeFailed ? (
        <div className="challenge-message">
          <h3>Today's challenge failed!</h3>
        </div>
      ) : (
        <div
          className={`tap-area ${isAnimating ? "animating" : ""}`}
          onClick={handleTap}
        >
          <img src="/images/tap-icon.png" alt="Tap" className="tap-image" />
        </div>
      )}

      {remainingTime && (
        <p>Time left: {Math.floor(remainingTime / (60 * 1000))} minutes</p>
      )}

      <div className="action-buttons">
        <button className="action-button">Ref</button>
        <button className="action-button">Task</button>
        <button className="action-button">Boost</button>
        <button className="action-button">Stats</button>
      </div>
    </div>
  );
};

// Debounce function to prevent rapid API requests
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

export default Game;
